import { ChakraProvider, cookieStorageManager } from "@chakra-ui/react";
import { DataHandlerProvider } from "@raiden/library-ui/components/DataHandler";
import { DataHandlerInfiniteProvider } from "@raiden/library-ui/components/DataHandlerInfinite";
import ErrorGlobal from "@raiden/library-ui/components/Error/Global";
import { ErrorBoundaryDegraded } from "@raiden/library-ui/components/ErrorBoundaryDegraded";
import { IconProvider } from "@raiden/library-ui/components/Icon";
import { DEFAULT_PER_PAGE } from "@raiden/library-ui/constants/api";
import browser from "@raiden/library-ui/constants/browser";
import {
  COOKIES_NAME_LIST,
  COOKIES_NAME_VALUE_ACCEPT_COOKIES,
  COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
} from "@raiden/library-ui/constants/cookies";
import { adminBaseUri } from "@raiden/library-ui/constants/routers/admin";
import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library-ui/constants/users";
import AuthProvider from "@raiden/library-ui/contexts/Auth";
import { ConfigurationProvider } from "@raiden/library-ui/contexts/Configuration";
import { GoogleTrackingProvider } from "@raiden/library-ui/contexts/GoogleTracking";
import { MaintenanceModeProvider } from "@raiden/library-ui/contexts/MaintenanceMode";
import { PreferencesProvider } from "@raiden/library-ui/contexts/Preferences";
import { SplashContext } from "@raiden/library-ui/contexts/Splash";
import { TranslationMessagesProvider } from "@raiden/library-ui/contexts/TranslationMessages";
import { apiGetErrorStatus } from "@raiden/library-ui/helpers/api";
import { googleGetTrackingState } from "@raiden/library-ui/helpers/google";
import {
  nextCheckMaintenance,
  nextGetAdminLayout,
  nextGetConfiguration,
  nextGetCookies,
  nextGetUri,
  nextGetUser,
  nextIsAuthorized,
  nextSetUser,
} from "@raiden/library-ui/helpers/next";
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";
import generateAdminUrl from "@raiden/library-ui/libraries/utils/generateAdminUrl";
import generateApiUri from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { I18nProvider } from "@react-aria/i18n";
import { OverlayProvider } from "@react-aria/overlays";
import { SSRProvider } from "@react-aria/ssr";
import cookie from "cookie";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import "focus-visible/dist/focus-visible";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-image-crop/dist/ReactCrop.css";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";
import ErrorBoundary from "../components/ErrorBoundary";
import { DATA_HANDLER_CONTEXT_VALUE } from "../constants/dataHandler";
import { DATA_HANDLER_INFINITIE_CONTEXT_VALUE } from "../constants/dataHandlerInfinite";
import theme from "../constants/theme";
import CookieBanner from "../containers/CookieBanner";
import MaintenanceLayout from "../containers/MaintenanceLayout";
import MaintenanceRequest from "../containers/MaintenanceRequest";
import Progress from "../containers/Progress";
import SignedInLayout from "../containers/SignedInLayout";
import SignedOutLayout from "../containers/SignedOutLayout";
import SignedSaml from "../containers/SignedSaml";
import TooManyRequest from "../containers/TooManyRequest";
import Unauthorized from "../containers/Unauthorized";
import { DefaultEnvironmentsProvider } from "../contexts/DefaultEnvironments";
import { GuardsProvider } from "../contexts/Guards";
import { useMaintenance } from "../hooks/maintenance";
import getTranslationMessages from "../libraries/utils/getTranslationMessages";
import "./styles.css";

dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);

const swrConfigValue = {
  revalidateOnFocus: false,
  errorRetryCount: 0,
  refreshWhenOffline: false,
};

const AUTH_FIELDS = ["users.admins.avatar"];

const CONFIGURATION_FIELDS = ["covers", "files", "departments"];

const ADDITIONAL_SIGNED_IN_FIELDS = [
  "environments",
  "insurances",
  "records_main_accounts",
  "subscriptions",
  "partners",
  "amenities_categories",
  "years",
];

function getConfigurationUrl({ isLoggedIn }) {
  const fields = [...CONFIGURATION_FIELDS];
  isLoggedIn && fields.push(...ADDITIONAL_SIGNED_IN_FIELDS);
  return generateApiUri({
    id: "@configuration",
    query: {
      fields,
    },
  });
}

/**
 * Retourne le layout à afficher.
 * @param {object} param0
 * @param {import("../types/Layout").LayoutName | null | undefined} param0.layout
 * @returns {import("react").FC}
 */
function getLayout({ layout }) {
  switch (layout) {
    case "signedOut":
      return SignedOutLayout;

    case "signedIn": {
      return SignedInLayout;
    }
    case "signedSaml":
      return SignedSaml;

    default:
      return SignedInLayout;
  }
}

/**
 * @param {string} [image="default"]
 * @returns {string}
 */
function setSrc(image = "default") {
  return generateAdminPath({
    id: "internal-assets",
    parameters: {
      filePath: `splashes/${image}.png`,
    },
    includeBasePath: true,
  });
}

/**
 * @typedef {object} Props
 * @property {import("../types/Page").PageInitialProps} [initialProps]
 * @property {Record<string, any>} cookies
 * @property {import("../types/Layout").LayoutName | null | undefined} layout
 * @property {boolean} tooManyRequest
 * @property {boolean} maintenanceRequest
 * @property {import("@raiden/library-ui/helpers/next/isAuthorized").NextIsAuthorizedReturn} authorized
 * @property {import("@raiden/library-ui/types/Configuration").Configuration} [configuration]
 * @property {import("@raiden/library-ui/types/User").UserBase} [user]
 * @property {import("@raiden/library-ui/helpers/next/getUser").NextGetUserReturn} [userData]
 * @property {any} [configurationError]
 * @property {string} [locale]
 * @property {object} initialTranslationMessages
 * @property {any} error
 */
/**
 * @param {Props & { Component: import("react").FC<import("../types/Page").PageInitialProps>}} props
 */
export default function App({
  Component,
  initialProps,
  cookies,
  layout,
  tooManyRequest,
  maintenanceRequest,
  authorized,
  user,
  configuration,
  configurationError,
  locale,
  initialTranslationMessages,
  error,
  userData,
}) {
  const isMaintenance = useMaintenance({ cookies });

  const colorModeManager = cookieStorageManager(
    cookie.serialize(
      "chakra-ui-color-mode",
      cookies["chakra-ui-color-mode"] ?? theme.config.initialColorMode,
    ),
  );

  const Layout = getLayout({ layout });

  nextSetUser(userData);

  /** @type {import("@raiden/library-ui/contexts/Splash").SplashContextValue} **/
  const SPLASH_CONTEXT_DEFAULT_VALUE = {
    setSrc,
  };

  return (
    <ErrorBoundaryDegraded>
      <GoogleTrackingProvider
        initialState={googleGetTrackingState({
          cookieValue: cookies[COOKIES_NAME_VALUE_ACCEPT_COOKIES],
        })}
        trackingKey={process.env.NEXT_PUBLIC_GOOGLE_TRACKING_KEY}
        trackingService={process.env.NEXT_PUBLIC_GOOGLE_TRACKING_SERVICE}>
        <SWRConfig value={swrConfigValue}>
          <TranslationMessagesProvider
            initialTranslationMessages={initialTranslationMessages}>
            {({ translationMessages }) => (
              <>
                {locale && (
                  <IntlProvider
                    locale={locale}
                    defaultLocale={process.env.NEXT_PUBLIC_DEFAULT_LOCALE}
                    messages={translationMessages}>
                    <I18nProvider locale={locale}>
                      <SSRProvider>
                        <ChakraProvider
                          theme={theme}
                          colorModeManager={colorModeManager}>
                          <IconProvider defaultSize="1rem">
                            <DataHandlerProvider
                              value={DATA_HANDLER_CONTEXT_VALUE}>
                              <DataHandlerInfiniteProvider
                                value={DATA_HANDLER_INFINITIE_CONTEXT_VALUE}>
                                <SplashContext.Provider
                                  value={SPLASH_CONTEXT_DEFAULT_VALUE}>
                                  <MaintenanceModeProvider>
                                    <AuthProvider
                                      initialUser={user}
                                      fields={AUTH_FIELDS}>
                                      <ConfigurationProvider
                                        configurationUrl={getConfigurationUrl({
                                          isLoggedIn:
                                            user?.user_type ===
                                            USERS_USER_TYPE_VALUE_ADMIN,
                                        })}
                                        initialConfiguration={configuration}
                                        initialConfigurationError={
                                          configurationError
                                        }>
                                        <PreferencesProvider
                                          initialPaginationPerPage={
                                            cookies[
                                              COOKIES_NAME_VALUE_PAGINATION_PER_PAGE
                                            ] ?? DEFAULT_PER_PAGE
                                          }>
                                          <DefaultEnvironmentsProvider
                                            cookies={cookies}>
                                            {isMaintenance ? (
                                              <MaintenanceLayout />
                                            ) : (
                                              <ErrorBoundary>
                                                <Layout>
                                                  <OverlayProvider
                                                    style={{
                                                      minHeight: "100%",
                                                    }}>
                                                    <GuardsProvider
                                                      guard={
                                                        initialProps?.guard
                                                      }>
                                                      {(() => {
                                                        if (tooManyRequest) {
                                                          return (
                                                            <TooManyRequest />
                                                          );
                                                        }
                                                        if (
                                                          maintenanceRequest
                                                        ) {
                                                          return (
                                                            <MaintenanceRequest />
                                                          );
                                                        }
                                                        if (error) {
                                                          return (
                                                            <ErrorGlobal
                                                              error={error}
                                                            />
                                                          );
                                                        }
                                                        if (
                                                          !authorized.locally ||
                                                          !authorized.globally
                                                        ) {
                                                          return (
                                                            <Unauthorized
                                                              authorized={
                                                                authorized
                                                              }
                                                            />
                                                          );
                                                        }
                                                        return (
                                                          <>
                                                            <Progress />
                                                            <Component
                                                              {...initialProps}
                                                            />
                                                            <CookieBanner />
                                                          </>
                                                        );
                                                      })()}
                                                    </GuardsProvider>
                                                  </OverlayProvider>
                                                </Layout>
                                              </ErrorBoundary>
                                            )}
                                          </DefaultEnvironmentsProvider>
                                        </PreferencesProvider>
                                      </ConfigurationProvider>
                                    </AuthProvider>
                                  </MaintenanceModeProvider>
                                </SplashContext.Provider>
                              </DataHandlerInfiniteProvider>
                            </DataHandlerProvider>
                          </IconProvider>
                        </ChakraProvider>
                      </SSRProvider>
                    </I18nProvider>
                  </IntlProvider>
                )}
              </>
            )}
          </TranslationMessagesProvider>
        </SWRConfig>
      </GoogleTrackingProvider>
    </ErrorBoundaryDegraded>
  );
}

/**
 * @param {object} appProps
 * @param {import("next").NextComponentType<import("../types/AppContext").PageContext, import("../types/Page").PageInitialProps>} appProps.Component
 * @param {import("next/dist/shared/lib/utils").AppTreeType} appProps.AppTree
 * @param {import("next").NextPageContext} appProps.ctx
 * @param {import("next/router").default} appProps.router
 */
App.getInitialProps = async function (appProps) {
  const {
    Component,
    router: { locale },
  } = appProps;
  const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

  const req = appProps.ctx.req;

  await nextCheckMaintenance({ res: appProps.ctx.res });

  const { cookies, unSecureCookies } = await nextGetCookies({
    req,
    whitelist: COOKIES_NAME_LIST.reduce(function (cookies, { id: cookieName }) {
      cookies[cookieName] = true;

      return cookies;
    }, {}),
  });

  const [translationMessages, userData] = await Promise.all([
    getTranslationMessages(locale, defaultLocale),
    nextGetUser({
      cookies,
      baseUri: adminBaseUri,
      locale,
      req,
      fields: AUTH_FIELDS,
    }),
  ]);

  const { user, error: userResponseError } = userData;

  const configurationResponse = !browser
    ? await nextGetConfiguration({
        baseUri: adminBaseUri,
        locale,
        cookies,
        req,
        configUrl: getConfigurationUrl({
          isLoggedIn: user?.user_type === USERS_USER_TYPE_VALUE_ADMIN,
        }),
      })
    : undefined;

  const configuration = configurationResponse?.configuration;
  const configurationError = configurationResponse?.error;

  const tooManyRequest =
    429 === apiGetErrorStatus({ error: userResponseError }) ||
    429 === apiGetErrorStatus({ error: configurationError });

  const maintenanceRequest =
    503 === apiGetErrorStatus({ error: userResponseError }) ||
    503 === apiGetErrorStatus({ error: configurationError });

  const error = configurationError || userResponseError;

  const uri = nextGetUri({ req: appProps.ctx.req });

  const initialProps = await Component.getInitialProps?.({
    ...appProps.ctx,
    configuration,
  });

  const layout = await nextGetAdminLayout({
    uri,
    pageLayout: initialProps?.layout,
    tooManyRequest,
    res: appProps.ctx.res,
    logged: user?.user_type === USERS_USER_TYPE_VALUE_ADMIN,
    user: user,
    redirections: {
      signedOut: generateAdminUrl({
        id: "login",
        query: {
          next: encodeURIComponent(
            `${uri.getPath()}${uri.getQuery() ? `?${uri.getQuery()}` : ""}`,
          ),
        },
        includeBasePath: true,
      }),
      signedIn: generateAdminUrl({ id: "dashboard", includeBasePath: true }),
    },
  });

  const authorized = await nextIsAuthorized({
    guard: initialProps?.guard,
    user: user,
    unSecureCookies,
    withEnvironmentGuard: true,
  });

  /** @type {Props} */
  const props = {
    initialProps,
    locale,
    layout,
    cookies: unSecureCookies,
    authorized,
    tooManyRequest,
    maintenanceRequest,
    user,
    userData,
    initialTranslationMessages: translationMessages,
    configuration,
    configurationError,
    error,
  };

  return props;
};
